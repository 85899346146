import React, {useEffect} from "react";
import styles from './home.scss';
import { MDBContainer } from "mdb-react-ui-kit";

function Introduction(props) {
    return (
        <div className="introduction-container">
            <MDBContainer breakpoint="lg" >
                <div className="confide-intro-head">
                    <h1>Empower Your Clarity</h1>
                    <div
                        style={{
                            position: "relative"
                        }}
                    >
                        <p className="text-[#586662] text-center">Confide in trusted advisors to seek great ideas!</p>
                        {/* <img src="/img/backgrounds/sections/blur_yellow.svg"
                            className="intro-header-blur-left"
                            loading="lazy" />
                        <img src="/img/backgrounds/sections/blur_yellow.svg"
                            className="intro-header-blur-right" loading="lazy" /> */}
                    </div>
                </div>
                <div className="introduction-container2 py-2 d-flex flex-column justify-content-center">
                    <div className="introduction-container-bg">
                        <div className="intro-confide">
                            <span style={{color:'#0505bf'}}>confide</span>
                            <span className="ms-2" style={{color:'yellow'}}>ideas</span>
                        </div>
                    </div>
                    
                </div>
            </MDBContainer>
        </div>
    )
}

export default Introduction;