import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";
import {useInView} from 'motion/react';
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ModalContext from "../../../Context/ModalContext";

function FirstOfferSection(props) {
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    const ref1 = useRef(null);
    const isInView1 = useInView(ref1, {once: true});
    console.log(authUser)  
    function _handleSignUp(e) {
      e.preventDefault();
      
      if (isAuthenticated) return;
      // console.log(authUser)  
      if (authUser) return;
      modalContext.setOpenSignUpModal(true);
    }

    return (<> {!isAuthenticated && 
          <section className="first-offer-section">
            <MDBContainer className="section-container" breakpoint="lg">
                <MDBRow className="section-title-row d-flex justify-content-center ">
                  <div  className="first-offer-section-title align-self-center">
                    <h1 className="mt-2 d-flex justify-content-center align-items-center">
                      <img 
                        loading="lazy"
                        ref={ref1}
                        className="first-offer-ribbon" src="/img/backgrounds/ribbon.png" 
                      />
                      <span className="ms-2 ms-md-4">First Time Client Offer</span>
                    </h1>
                    <MDBRow>
                      <MDBCol size={0} lg={1} md={0} xs={0}/>
                      <MDBCol size={12} lg={12} md={12} xs={12}>
                        <p 
                        style={{
                          paddingTop: '7px'
                        }}>Receive an additional 20% credit after your first paid reading!</p>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBRow>
                
                <MDBRow className="first-offer-description-container justify-content-center fist-offer-tips">
                    <MDBCol size={12} lg={9} md={9} sm={9} xs={9} className="text-center">
                      <p> Sign up  and enjoy your first chat today!</p>
                    </MDBCol>

                    <MDBCol size={4} lg={3} md={3} sm={3} xs={3} className="text-center fist-offer-tips w-fit-content">
                      <MDBBtn 
                        className="d-flex align-items-center justify-content-center sign-up-btn py-2" 
                        size="xl" rounded={true}
                        onClick={_handleSignUp}
                      >
                        <span className="text-dark fs-120">Sign up</span>
                      </MDBBtn>
                  </MDBCol>
                </MDBRow>
            </MDBContainer>
          </section>
        }
      </>
    )
}

export default FirstOfferSection;