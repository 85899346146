import { createSlice } from '@reduxjs/toolkit';
import { getSHA256 } from '../../Utils/utils';

const changedAdvisorSlice = createSlice({
    name: 'changedAdvisor',
    initialState: {
        advisors: null,
        hash256: null
    },
    reducers: {
        setChangedAdvisors: (state, action) => {
            console.log('setChangedAdvisors', action.payload);
            state.advisors = action.payload;
            state.hash256 = getSHA256(action.payload);
        },
        removeChangedAdvisors: (state, action) => {
            console.log('removeChangedAdvisors');
            state.advisors = null;
            state.hash256 = null;
        }
    }
});

export const {setChangedAdvisors, removeChangedAdvisors} = changedAdvisorSlice.actions;

export const getChangedAdvisorsHash256 = (state) => state.changedAdvisor.hash256;
export const getChangedAdvisors = (state) => state.changedAdvisor.advisors;
export const getLastestAdvisorInfo = (state, advisor) => {
    if(!advisor) return null;

    const chgAdvisor = state.changedAdvisor.advisors?.find(a=>a._id === advisor._id);
    if(chgAdvisor) {
        advisor = {
            ...advisor,
            rate_per_min_chat: chgAdvisor.rate_per_min_chat,
            isLoggedIn: chgAdvisor.isLoggedIn,
            online: chgAdvisor.online,
            isBusy: chgAdvisor.isBusy,
            isAvailableChat:chgAdvisor.isAvailableChat,
            isAvailableCall:chgAdvisor.isAvailableCall,
            isAvailableVideo:chgAdvisor.isAvailableVideo,
        };
    }

    advisor.serviceStatus = 'offline';
    advisor.serviceChatStatus = 'offline';

    if(advisor.isLoggedIn != true) {
        advisor.serviceChatStatus = 'offline';
        advisor.serviceStatus = 'offline';
    } else {
        if(advisor.isBusy) {
            advisor.serviceChatStatus = 'busy';
            advisor.serviceStatus = 'busy';
        } else {
            advisor.serviceChatStatus = advisor.isAvailableChat ? 'online' : 'offline';
        }
    }

    return advisor;
}

export default changedAdvisorSlice.reducer;