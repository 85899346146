import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser, purgeAuth } from "../Store/Reducer/authReducer";
import { useNavigate } from "react-router-dom";
import { removeChangedAdvisors } from "../Store/Reducer/changedAdvisorReducer";
import { removeAlarms } from "../Store/Reducer/alarmReducer";
import { v_url } from "../Utils/utils";
import ApiService from "../Core/Service/ApiService";
import LoadingIndicator from "./LoadingIndicator/LoadingIndicator";
import { MDBContainer } from "mdb-react-ui-kit";

const Logout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);

    useEffect(()=>{
        (async () => {
            try {
                if(authUser) {
                    await ApiService.get('/user/auth/logout');
                }
            } finally {
    
            }
            
            dispatch(purgeAuth());
            dispatch(removeChangedAdvisors());
            dispatch(removeAlarms());
            
            navigate(v_url('/'));
        })()
    }, [authUser])

    return <LoadingIndicator fullScreen={true} />
}

export default Logout;