import React, {useState, useEffect, useContext, useMemo, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { memoizedGetAuthUser } from '../../Store/Reducer/authReducer';
import { getAvatarUrl, v_url } from '../../Utils/utils';
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBIcon,
    MDBModalFooter,
    MDBRadio,
    MDBSpinner,
} from "mdb-react-ui-kit";
import { WhenToNotify } from "../../Constants/constants";
import ModalContext from "../../Context/ModalContext";
import ApiService from "../../Core/Service/ApiService";

const NotifySettingModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const open = modalContext.openNotifySettingModal;
    const setOpen = modalContext.setOpenNotifySettingModal;
    const [formData, setFormData] = useState({
        email: WhenToNotify.NEVER
    })
    const [saving, setSaving] = useState(false);

    const onNotifySettingChanged = (method, e) => {
        setFormData({
            ...formData,
            [method]: e.target.value
        })
    }

    useEffect(() => {
        if(open) {
            ApiService.get(`/client/advisor_notify/${modalContext.modalData.advisor._id}`)
            .then(response => {
                if(response.data.advisor_notify) {
                    setFormData(JSON.parse(response.data.advisor_notify.methods));
                } else {
                    setFormData({
                        email:WhenToNotify.NEVER
                    })
                }
            })
        }
    }, [open]);

    const close = () => {
        setOpen(false);
    }

    const handleSave = () => {
        let methods = "";
        if(formData.email != WhenToNotify.NEVER) methods = JSON.stringify(formData);

        setSaving(true);
        const data = {
            aId: modalContext.modalData.advisor._id,
            methods
        };
        ApiService.post('/client/advisor_notify', data).then(response=>{
            setTimeout(() => {
                close();
            }, 200);
        }).finally(() => {
            setSaving(false);
        })
    }

    return (
        <MDBModal   className="notify-setting-modal" 
                    open={open} 
                    setOpen={setOpen} 
                    closeOnEsc={false}
                    staticBackdrop 
                    tabIndex='-1' >
            <MDBModalDialog style={{width: 400}}>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle className="text-center w-100">
                            Subscribe
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody className="d-flex p-4 flex-column justify-content-center">
                        {open && <>
                            <div className="d-flex align-items-center">
                                When 
                                <img 
                                    src={getAvatarUrl(modalContext.modalData?.advisor?.avatar)} 
                                    className="circle-avatar-50 ms-2" 
                                    style={{border: '3px solid #e1d806'}}
                                />
                                <span className="fw-bold mx-2" >{modalContext.modalData?.advisor?.username}</span> 
                                is online,
                            </div>
                            <div className="d-flex mt-3 align-items-start ">
                                <div className="d-flex align-items-center">
                                    <MDBIcon fas icon="envelope" size="lg" color="warning" />
                                    <span className="ms-2">Email Me: </span>
                                </div>
                                <div className="ms-4 justify-content-end">
                                    <MDBRadio 
                                        name='notify_email' 
                                        id='notify_email1' 
                                        value='always' 
                                        label='Always' 
                                        checked={formData.email == WhenToNotify.ALWAYS} 
                                        onChange={(e) => onNotifySettingChanged('email', e)}
                                    />
                                    <MDBRadio 
                                        name='notify_email' 
                                        id='notify_email2' 
                                        value='nexttime' 
                                        label='Next Time Only' 
                                        checked={formData.email == WhenToNotify.NEXTTIME} 
                                        onChange={(e) => onNotifySettingChanged('email', e)}
                                    />
                                    <MDBRadio 
                                        name='notify_email' 
                                        id='notify_email3' 
                                        value='never' 
                                        label="Don't Email Me" 
                                        checked={formData.email == WhenToNotify.NEVER} 
                                        onChange={(e) => onNotifySettingChanged('email', e)}
                                    />
                                </div>
                            </div>
                        </>}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="d-flex w-100 px-4">
                            <MDBBtn className="flex-fluid me-2" color="default" onClick={close}>Cancel</MDBBtn>
                            <MDBBtn className="flex-fluid confideas-primary-btn" onClick={handleSave}>
                                {saving && <MDBSpinner size='sm' />}
                                <span className="ms-2">Save</span>
                            </MDBBtn>
                        </div>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default NotifySettingModal;