import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { UserRole } from "../../Constants/constants";
import Moment from "react-moment";
import { formatDate, getAvatarUrl, text2html } from "../../Utils/utils";
import { MDBIcon } from "mdb-react-ui-kit";

function LMessage(props) {
    const {content, time} = props.message;
    const { sender, showAvatar } = props;

    return (
        <div className="mb-1">
            <div className="one-message partner d-flex px-2">
                <div className="avatar d-none d-md-block">
                    {showAvatar && <img src={getAvatarUrl(sender.avatar)} loading="lazy" /> }
                </div>
                <div className="content mx-2">
                    <div dangerouslySetInnerHTML={{ __html: text2html(content)}}></div>
                    <div className="d-flex justify-content-end msg-time">
                        <Moment format='HH:mm' className="px-1">{time}</Moment>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RMessage(props) {
    const {content, time, ack} = props.message;
    const {sender, showAvatar} = props;

    return (
        <div className="d-flex mb-1 justify-content-end">
            <div className="one-message mine d-flex px-2 justify-content-end">
                <div className="content mx-2">
                    <div dangerouslySetInnerHTML={{ __html: text2html(content)}}></div>
                    <div className="d-flex justify-content-end px-2 msg-time">
                        <Moment format='HH:mm' className="px-1">{time}</Moment>
                    </div>
                </div>
                <div className="avatar d-none d-md-block">
                    {showAvatar && <img src={getAvatarUrl(sender.avatar)} loading="lazy" /> }
                </div>
            </div>
        </div>
    )
}

const Messages = React.memo((props) => {
    const { chat, messages } =  props;
    const authUser = useSelector(memoizedGetAuthUser);
    const partner = authUser.role === UserRole.ADMIN ? null : ((authUser._id === chat.cId._id) ? chat.aId : chat.cId);

    let prev_sender = '';
    return <div className="message-history-container">
        <div className="message-history-container2 d-flex flex-column">
            {   messages.map((m, index) => {
                    const sender = m.sender === UserRole.CLIENT ? m.cId : m.aId;
                    const showAvatar = (sender != prev_sender);
                    prev_sender = sender;

                    let time = null;
                    if(authUser.role === UserRole.CLIENT) time = new Date(m.client_time);
                    if(authUser.role === UserRole.ADVISOR) time = new Date(m.advisor_time);
                    if(authUser.role === UserRole.ADMIN) time = new Date(m.createdAt);

                    const message = {
                        content: m.message,
                        time,
                    }

                    if(authUser.role === UserRole.ADMIN) {
                        if(sender === m.cId) {
                            return <LMessage key={index} message={message} sender={chat.cId} showAvatar={showAvatar}/>
                        } else {
                            return <RMessage key={index}  message={message} sender={chat.aId} showAvatar={showAvatar} />
                        }
                    } else {
                        if((authUser.role != UserRole.ADMIN && sender === authUser._id)) {
                            return <RMessage key={index}  message={message} sender={authUser} showAvatar={showAvatar} />
                        } else {
                            return <LMessage key={index} message={message} sender={partner} showAvatar={showAvatar}/>
                        }
                    }
                })
            }
        </div>
    </div>
})

export default Messages;