import React from "react";
import classname from 'classnames';
import styles from './Advisor.scss'
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { capitalizeFirstLetter, getAvatarUrl, text2html, v_url } from "../../Utils/utils";
import { IsAuthenticated, IsFavoriteAdvisor, addFavoriteAdvisor, memoizedGetAuthUser, removeFavoriteAdvisor } from "../../Store/Reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { toast } from "react-toastify";
import { UserRole } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { memoizedGetChat } from "../../Store/Reducer/chatReducer";
import ApiService from "../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator'
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { useEffect } from "react";
import CommPanel from "./CommPanel";
import { getLastestAdvisorInfo } from "../../Store/Reducer/changedAdvisorReducer";

function Advisor(props) {
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { advisor } = props;
    const aId = advisor._id;
    const [addingFavorite, setAddingFavorite] = useState(false);
    const isFavoriteAdvisor = useSelector((state) => IsFavoriteAdvisor(state, aId))
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [availCoupon, setAvailCoupon] = useState(null);
    const advisorUpdtInfo = useSelector((state) => getLastestAdvisorInfo(state, props.advisor));

    useEffect(()=>{
        if(isAuthenticated) {
            if(props.availCoupon !== undefined) {   // if props.availCoupon is not undefined, it indicates parent component alreay searched coupons for this advisor
                setAvailCoupon(props.availCoupon);
            } else {
                ApiService.get('/client/coupon/available/' + aId).then(result=>{
                    setAvailCoupon(result.data.coupon);
                })
            }
        }
    }, [props.availCoupon, isAuthenticated])

    const gotoAdvisorDetailPage = () => {
        navigate(v_url('/psychic/' + advisor.username.replaceAll(' ', '-')));
    }

    const onAddFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/add', {aId}).then(result => {
            dispatch(addFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const onRemoveFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/remove', {aId}).then(result => {
            dispatch(removeFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const renderNormal = () => {
        return (
            <>
                <div className="main-info d-flex flex-column justify-content-center align-items-begin" >
                    <div className="avatar-container position-relative">
                        <div 
                            className="avatar"
                            style={{backgroundImage: `url(${(getAvatarUrl(advisor?.avatar))})`}}
                            onClick={gotoAdvisorDetailPage}
                        />
                        {availCoupon &&
                        <div className="d-flex justify-content-center align-items-center coupon" title={`Free ${availCoupon.availMinutes} mins`}>
                            {availCoupon.availMinutes}
                        </div>
                        }

                        {isAuthenticated && authUser?.role === UserRole.CLIENT &&
                            <div className="advisor-action">
                                { isFavoriteAdvisor && 
                                    <div onClick={onRemoveFavorite} 
                                        className="trash-favorite-btn d-flex justify-content-center align-items-center"
                                        title="Remove from favorite advisor"
                                    >
                                        <MDBIcon fas icon='heart' color="danger" size="lg" />
                                    </div> 
                                }
                                { !isFavoriteAdvisor && 
                                    <div onClick={onAddFavorite} 
                                        className="add-favorite-btn d-flex justify-content-center align-items-center"
                                        title="Add to favorite advisor"
                                    >
                                        <MDBIcon far icon='heart' color='danger' size="lg" />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className='px-2 mt-2'
                        style={{
                            position: 'relative'
                        }}>
                        <div>
                            <div style={{
                                padding: "2px",
                                color: '#333',
                                position: 'absolute',
                                top: -5,
                                fontSize: '80%',
                                right: 0
                            }}>{capitalizeFirstLetter(advisorUpdtInfo?.serviceChatStatus)}</div>
                        </div>
                        <div className="advisor-name">{advisor?.username}</div>
                        <div className="service-name mt-1">{advisor.service_name}</div>
                        <div className="service-description mt-1 fs-90"
                            style={{
                                fontSize: '17px',
                                WebkitLineClamp: '2',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                height: '50px',
                                whiteSpace: 'normal'
                            }}
                            dangerouslySetInnerHTML={{ __html: text2html(advisor.service_description)}}
                        ></div>
                    </div>
                </div>
    
                <div className='px-2 mt-2'>
                    <CommPanel advisor={advisorUpdtInfo} />
                </div>
            </>
        )
    }

    return <>
        <div className="flex-column advisor-preview normal p-1">
            { renderNormal() }
        </div>
    </>
}

export default Advisor;