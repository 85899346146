import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";


function BecomeAdvisorSection(props) {
    const navigate = useNavigate();

    const applyAdvissor = (e) => {
      e.preventDefault();
      navigate(v_url('/advisor_auth/signup'));
    }
    return (
        <section className="become-advisor-section">
            <MDBContainer className="section-container" breakpoint="lg">

              <MDBRow className="section-title-row d-flex" style={{ 
                paddingBottom: '10px'
              }}>
              
              <MDBCol className="d-flex flex-row align-self-center" size={12}>
                <h1 className="p-0 mb-0">Become an Advisor</h1>
              </MDBCol>
                  <MDBCol className="d-flex flex-row align-self-center" size={12}>
                    <MDBRow>
                      <MDBCol className="align-self-center" size={12}>
                        <MDBRow>
                          <MDBCol className="d-flex flex-row align-self-center text-center" size={12}>
                            <p className="text-left">Do you have a passion for sharing your insights? 
                            We  welcome consultants in all areas of expertise 
                            to join us and make a great impact! </p>
                          </MDBCol>
                          <MDBCol className="align-self-center text-center" size={12}>
                            <MDBBtn
                              onClick={applyAdvissor}
                              size="md"
                              className="apply-btn"
                            >
                              <span className="apply-span">APPLY NOW</span>
                            </MDBBtn>
                          </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </section>
    )
}

export default BecomeAdvisorSection;