import React, {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { memoizedGetAuthUser, purgeAuth, setAuth } from '../../Store/Reducer/authReducer';
import { isValidEmail, v_url, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBSpinner,
    MDBInput
} from "mdb-react-ui-kit";
import { UserRole, UserStatus } from "../../Constants/constants";
import ModalContext from "../../Context/ModalContext";
import { useContext } from "react";
import { removeChat } from "../../Store/Reducer/chatReducer";
import { addAlarms, removeAlarms } from "../../Store/Reducer/alarmReducer";
import { IsChatSocketConnected } from "../../Store/Reducer/socketReducer";
import JwtService from "../../Core/Service/JwtService";
import PasswordInput from "../PasswordInput/PasswordInput";

const LoginModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const [loggingIn, setLoggingIn] = useState(false);
    const [resettingPasswd, setResettingPasswd] = useState(false);
    const emailInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const authUser = useSelector(memoizedGetAuthUser);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const loginFrmRules = {
        email: {
            required: 'email'
        },
        password: {
            required: 'string',
            minlength: 6
        }
    }
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        if(modalContext.openSignInModal) {
            emailInputRef.current?.focus();
        }
    }, [modalContext.openSignInModal]);

    const closeModal = () => {
        formData.email = '';
        formData.password = '';
        setInputErrors({});

        modalContext.setOpenSignInModal(false);
    }
    const toggleOpenSignUpModal = (e) => {
        modalContext.setOpenSignInModal(false);
        modalContext.setOpenSignUpModal(true);
    }
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const handleResetPassword =(e) => {
        e.preventDefault();
        if(!isValidEmail(formData.email)) {
            toast.warn("Please input email address.");
            return;
        }

        setResettingPasswd(true);

        const data = {
            email: formData.email
        }
        ApiService.post('/user/auth/resetPasswd', data).then(result=>{
            toast.success("Your new password has been sent to your email");
        })
        .finally(()=>{
            setResettingPasswd(false);
        });
    }
    const handleLogin = (e) => {
        e.preventDefault();

        dispatch(removeChat());
        dispatch(removeAlarms());

        let valid_ret = validateForm(formData, loginFrmRules);
        if(valid_ret.result === true) {
            setInputErrors({});
            setLoggingIn(true);

            const data = {
                email: formData.email,
                password: formData.password,
            }
            ApiService.post('/user/auth/login', data).then(result => {
                const {authUser, authToken} = result.data;
                dispatch(setAuth({
                    authUser,
                    authToken
                }));

                setLoggingIn(false);
                modalContext.setOpenSignInModal(false);
    
                let redirectUrl = '';
                if(authUser.role === UserRole.ADVISOR) {
                    if(authUser.status === UserStatus.EMAIL_VERIFIED) {
                        redirectUrl = v_url('/advisor_auth/signup-step');
                    } else if(authUser.status === UserStatus.WAITING) {
                        redirectUrl = v_url('/advisor/auth/undereview');
                    } else if(authUser.status === UserStatus.DECLINE_ADVISOR) {
                        modalContext.setOpenDeclineAdvisorModal(true);
                    } else {
                        redirectUrl = v_url('/advisor/dashboard');
                    }
                }
                else if(authUser.role === UserRole.CLIENT) {
                    redirectUrl = v_url('/all_advisors');
                }
                else if(authUser.role === UserRole.ADMIN) {
                    redirectUrl = v_url('/admin/dashboard')
                }

                setTimeout(()=>{
                    navigate(redirectUrl);
                    // window.location.href = redirectUrl;
                }, 200);
            })
            .catch((error)=>{
                toast.warn(error.response.data.message);
            })
            .finally(()=>{
                setLoggingIn(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    return (
        <MDBModal className="login-modal" open={modalContext.openSignInModal} setOpen={modalContext.setOpenSignInModal} tabIndex='-1' >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className='d-flex justify-content-center'>
                        <MDBModalTitle></MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        <form className='d-flex w-80 flex-column'>
                            <div className="text-center">
                                <h5>Welcome to Confideas</h5>
                            </div>
                            <div className='mt-4'>
                                <MDBInput   type='email' 
                                            label="Email" 
                                            name='email' 
                                            required 
                                            tabIndex='1'
                                            ref={emailInputRef}
                                            value={formData.email} 
                                            onChange={handleInputChange} />
                                {inputErrors.email && <div className='error'>{inputErrors.email}</div>}
                            </div>
                            <div className='mt-4'>
                                <PasswordInput
                                    label="Password"
                                    name="password"
                                    tabIndex='2'
                                    value={formData.password}
                                    handleInputChange={handleInputChange}
                                    error={inputErrors.password}
                                />
                            </div>
                            <div className='mt-3 d-flex forgot-password'>
                                <div className="" style={{color: '#111', fontSize: '90%'}}>Forgot Password?</div>
                                <div className="flex-fluid d-flex justify-content-end reset-password" onClick={handleResetPassword}>
                                    {resettingPasswd && <div className="d-flex align-items-center">
                                        <MDBSpinner size="sm" role='status'/>
                                        <span className="ms-1">Resetting password</span>
                                    </div>}
                                    {!resettingPasswd && <span>Reset your password</span>}
                                </div>
                            </div>
                            <div className='mt-4'>
                                <MDBBtn 
                                    tabIndex='3'
                                    style={{
                                        backgroundColor: 'rgb(251, 233, 83)',
                                        color: '#111',
                                        width: '100%',
                                        textTransform:'none'
                                    }}
                                    type='submit'
                                    onClick={handleLogin}
                                    disabled={loggingIn}
                                >
                                    {(() => {
                                        if(loggingIn) {
                                            return <>
                                                <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                Logging in
                                            </>
                                        } else  {
                                            return <span>Log in</span>
                                        }
                                    })()}
                                </MDBBtn>
                            </div>
                        </form>

                        <div className='w-80 mt-4 d-flex sign-up'>
                            <div className='me-2'>Don't have an account yet?</div>
                            <div>
                                <a onClick={toggleOpenSignUpModal} 
                                    style={{
                                        color: 'rgb(129, 28, 145)',
                                        cursor: 'pointer'
                                    }}
                                    tabIndex='-1'
                                >
                                    Register <strong>here</strong>
                                </a>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default LoginModal;