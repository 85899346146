import React, {useEffect, useState} from "react";
import ApiService from "../../Core/Service/ApiService";
import { addDate, getAvatarUrl, isMobileScreen, scrollDownBottom, text2html, validateForm } from "../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { MDBBtn, MDBCheckbox, MDBRadio, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import SVG from 'react-inlinesvg';
import './ChatOffMessage.scss';
import { ChatOffMessageType, ChatOffThreadStatus, UserRole } from "../../Constants/constants";
import Moment from "react-moment";
import { useRef } from "react";
import { ackChatOffThread, addChatOffMessage, closeChatOffThread, getChatOffThreadHash, getChatOffThreads } from "../../Store/Reducer/chatoffMsgReducer";
import { confirm_alert } from "../ConfirmAlert/ConfirmAlert";

const ChatOffMessage = React.memo((props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const authUser = useSelector(memoizedGetAuthUser);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        message: '',
        withFreeMinutes: false,
        freeMinutes: 0,
        expire_days: 21,
        expireAt: addDate(new Date(), 21),
    });
    const [inputErrors, setInputErros] = useState({});
    const [action, setAction] = useState({
        action: ''
    })
    const [closingThread, setClosingThread] = useState(false);
    const messagesRef = useRef(null);
    const {partner} = props;
    const [availCoupon, setAvailCoupon] = useState();
    const [thread, setThread] = useState();
    const chatoff_threads = useSelector(getChatOffThreads);
    const curr_thread_hash = useSelector((state) => getChatOffThreadHash(state, thread?._id))

    useEffect(()=>{
        if(partner && authUser) {
            const url = `${authUser.role === UserRole.CLIENT ? 'client' : 'advisor'}/coupon/available/${partner._id}`;
            ApiService.get(url).then(response => {
                setAvailCoupon(response.data.coupon);
                props.setAvailCoupon(response.data.coupon);
            })
        }
    }, [authUser]);

    useEffect(()=>{
        if(curr_thread_hash) {
            const curr_updt_thread = chatoff_threads.find(t=>t._id == thread._id);
            if(curr_updt_thread?.unread_msg_count > 0) {
                setMessages([
                    ...messages,
                    curr_updt_thread.last_message
                ]);
                if(curr_updt_thread.last_message.freeMinutes > 0) {
                    const newCoupon = {
                        freeMinutes: curr_updt_thread.last_message.freeMinutes,
                        availMinutes: curr_updt_thread.last_message.freeMinutes
                    };
                    setAvailCoupon(newCoupon);
                    props.setAvailCoupon(newCoupon);
                }
                setTimeout(()=>{
                    dispatch(ackChatOffThread(thread._id));
                }, 200);
                scrollDownToBottom();
            }
            setThread(curr_updt_thread);
        }
    }, [curr_thread_hash]);

    useEffect(()=>{
        if(props.thread) {
            setThread(props.thread);
            getMessages(props.thread._id);
        }
    }, [props.thread]);

    const getMessages = (thread_id) => {
        setLoading(true);
        ApiService.get(`/chatoff_thread/${thread_id}/messages`).then(response=>{
            setMessages(response.data.messages);
            dispatch(ackChatOffThread(thread_id));
            scrollDownToBottom();
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const handleFormInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleCancelEdit = (e) => {
        e.preventDefault();
        setEditMode(false);
        setFormData({
            message: '',
            withFreeMinutes: false,
            freeMinutes: 0,
            expire_days: 21,
            expireAt: addDate(new Date(), 21),
        })
    }

    const handleSendMessage = (e, type) => {
        e.preventDefault();

        const rules = {};
        if(type == ChatOffMessageType.MESSAGE) {
            rules.message = {
                required: 'string',
                label: 'Message'
            }
        }

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result == true) {
            setInputErros({});

            setAction(type);

            const data = {
                ...formData,
                thread_id: thread ? thread._id : '',
                partner_id: partner._id,
                type: ChatOffMessageType.MESSAGE,
            };
            
            ApiService.post('/chatoff_message/create', data).then(response => {
                const newMessage = {
                    ...response.data.newMessage,
                    sender: authUser,
                    receiver: partner
                };

                if(formData.withFreeMinutes) {
                    const newCoupon = {
                        freeMinutes: formData.freeMinutes,
                        availMinutes: formData.freeMinutes
                    };
                    setAvailCoupon(newCoupon);
                    props.setAvailCoupon(newCoupon);
                }

                setMessages([
                    ...messages,
                    newMessage
                ])
                setThread(response.data.thread);
                setFormData({
                    message: ''
                })
                dispatch(addChatOffMessage({
                    message: newMessage,
                    is_recv_msg: false,
                    aId: authUser.role === UserRole.ADVISOR ? authUser : partner,
                    cId: authUser.role === UserRole.CLIENT ? authUser : partner
                }))
                setEditMode(false);
                scrollDownToBottom();
            })
            .finally(()=>{
                setAction('');
            });
        } else {
            setInputErros(valid_ret.errors)
        }
    }

    const onReplyClicked = (e) => {
        setEditMode(true);
        scrollDownToBottom();
    }

    const scrollDownToBottom = () => {
        setTimeout(()=>{
            if(isMobileScreen()) {
                scrollDownBottom();
            } else {
                messagesRef.current.parentElement.scroll({
                    top: messagesRef.current.clientHeight,
                    behavior: 'smooth'
                })
            }
        }, 100);
    }

    const handleCloseThread = (e) => {
        confirm_alert({
            message: 'Do you want to close this thread?',
            ok: () => {
                setClosingThread(true);
                ApiService.post('/chatoff_thread/close', {thread_id: thread._id}).then(response => {
                    setThread({
                        ...thread,
                        status: ChatOffThreadStatus.CLOSED
                    })
                    dispatch(closeChatOffThread(thread._id));
                })
                .finally(()=>{
                    setClosingThread(false);
                })                
            },
        })
    }

    const onWithFreeMinutesChanged = (e) => {
        setFormData({
            ...formData,
            freeMinutes: formData.withFreeMinutes ? 0 : 3,
            expire_days: formData.withFreeMinutes ? 0 : 21,
            expireAt: formData.withFreeMinutes ? null : addDate(new Date(), 21),
            withFreeMinutes: !formData.withFreeMinutes
        })
    }

    const onFreeMinutesChanged = (m) => {
        setFormData({
            ...formData,
            freeMinutes: m
        })
    }

    const renderMessages = () => {
        if(messages?.length > 0) {
            return messages.map((message, index) => {
                const isMyMessage = message.sender._id === authUser._id ? true : false;
                return (
                    <div className={`one-message p-2 mb-2 ${isMyMessage ? 'my-message' : 'partner-message'}`} key={index}>
                        <div className="d-flex align-items-center position-relative">
                            <div className="sender d-flex align-items-center">
                                <img className="circle-avatar-30" src={getAvatarUrl(message.sender.avatar)} loading="lazy"/>
                                <span className="ms-2 fw-bold">
                                    {isMyMessage ? 'You' : message.sender.username}: 
                                </span>
                            </div>
                            <div className="ms-4 sent-time" style={{color: '#888', fontSize: '90%'}}>
                                <Moment format="YYYY-MM-DD HH:mm">{message.createdAt}</Moment>
                            </div>
                            {message.freeMinutes > 0 && <div className="coupon" style={{right:0, top: 0, color: '#888'}}>
                                {message.freeMinutes}
                            </div>}
                        </div>
                        <div>
                            {message.freeMinutes > 0 && <>
                                <div className="comment ms-2" style={{fontStyle:'italic'}}>
                                    You have {authUser.role === UserRole.CLIENT ? 'received' : 'sent'} {message.freeMinutes} free minutes. <br/>
                                    Exp Date: <Moment format="YYYY-MM-DD">{message.freeMinutes_expireAt}</Moment> 
                                </div>
                            </>}
                            <div 
                                className="px-2 py-1"
                                dangerouslySetInnerHTML={{ __html: text2html(message.message)}}
                            />
                        </div>
                    </div>
                )
            })
        } else {
            return loading ? '' : <div className="d-flex justify-content-center align-items-center">
                <div className="ms-2 mt-1">No Messages</div>
            </div>
        }
    }

    const renderEditSection = () => {
        return (
            <div className={`${isMobileScreen() ? 'fixed-action-bar' : ''} p-2 p-md-2`} style={{background: '#fff'}}>
                <div className="w-100">
                    <div>
                        <MDBTextArea 
                            name='message'
                            value={formData.message}
                            rows={3}
                            onChange={handleFormInputChange}
                        />
                        {inputErrors.message && <div className='error'>{inputErrors.message}</div>}
                        
                        {authUser?.role == UserRole.ADVISOR && <>
                            <div className="mt-1 d-flex align-items-center">
                                <MDBCheckbox label='Offer Free Minutes'
                                            name='withFreeMinutes'
                                            checked={formData.withFreeMinutes && !availCoupon}
                                            onChange={onWithFreeMinutesChanged}
                                            disabled={availCoupon ? true : false}
                                />

                                {formData.withFreeMinutes && <>
                                    <div className="value ms-4">
                                        <MDBRadio name='freeMinute' 
                                            id='freeMinutes3' 
                                            value={3} 
                                            label='3 mins' 
                                            inline 
                                            defaultChecked
                                            onClick={()=>onFreeMinutesChanged(3)}/>
                                        <MDBRadio name='freeMinute' 
                                            id='freeMinutes5' 
                                            value={5} 
                                            label='5 mins' 
                                            inline 
                                            onClick={()=>onFreeMinutesChanged(5)}/>
                                    </div>
                                </>}
                            </div>
                        </>}
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                        <MDBBtn color="default" onClick={handleCancelEdit} className="">Cancel</MDBBtn>

                        <MDBBtn 
                            className="confideas-primary-btn ms-4" 
                            onClick={e=>handleSendMessage(e, ChatOffMessageType.MESSAGE)} 
                            disabled={action === 'send_message'}
                        >
                            {action === ChatOffMessageType.MESSAGE && <div className="d-flex align-items-center">
                                <MDBSpinner size="sm" />
                                <span className="ms-2">Sending</span>
                            </div>}
                            {!(action === ChatOffMessageType.MESSAGE) && <span className="px-2">Send</span>}
                        </MDBBtn>
                    </div>
                </div>
            </div>
        )
    }

    const renderActionBar = () => {
        return (
        <div className={`${isMobileScreen() ? 'fixed-action-bar' : 'action'} d-flex justify-content-center align-items-center`}>
            <MDBBtn 
                className="me-4"
                color="default" 
                style={{background: '#fefefe'}} 
                onClick={handleCloseThread} 
                disabled={!thread || closingThread}
            >
                {closingThread && <div className="d-flex align-items-center justify-content-center">
                    <MDBSpinner size="sm" />
                    <span className="ms-2">Closing</span>
                </div>}
                {!closingThread && <span>Close Thread</span>}
            </MDBBtn>

            <MDBBtn 
                className="confideas-primary-btn" 
                style={{width: 150}} 
                onClick={onReplyClicked}
            >
                {thread ? 'Reply' : 'Contact'}
            </MDBBtn>
        </div>
        )
    }

    const messageSectionMB = () => {
        if(!isMobileScreen()) {
            return editMode ? 0 : 40;
        } else {
            return editMode ? (authUser.role === UserRole.CLIENT ? 140 : 180) : 50;
        }
    }

    if(authUser) {
        return (
            <div className="chatoff-message d-flex flex-column" ref={messagesRef}>
                <div className="flex-fluid messages p-2" style={{marginBottom: messageSectionMB()}}>
                    { renderMessages() }
                </div>
            
                {(()=>{
                    if(thread?.status != ChatOffThreadStatus.CLOSED) {
                        if(editMode) {
                            return renderEditSection();
                        } else {
                            return renderActionBar();
                        }
                    } else {
                        return (
                            <div 
                                className={`${isMobileScreen() ? 'fixed-action-bar' : 'expired'}  p-3 text-center`}
                                style={{color: '#eea1ae'}}
                            >
                                This thread was expired.
                            </div>
                        )
                    }
                })()}
            </div>
        )
    } else {
        return <></>
    }

});

export default ChatOffMessage;