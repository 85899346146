import React, {useState , useEffect} from "react";
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import { MDBBtn, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { SettingFields, TEST_MODE, UserRole } from "../../Constants/constants";
import ApiService from "../../Core/Service/ApiService";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import JwtService from "../../Core/Service/JwtService";
import { floatV, v_url } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";

const CommPanel = React.memo((props) => {
    const [checkingAvailCoupon, setCheckingAvailCoupon] = useState(false);
    const authUser = useSelector(memoizedGetAuthUser);
    const isAuthenticated = useSelector(IsAuthenticated);
    const modalContext = useContext(ModalContext);
    const navigate = useNavigate();
    const { advisor } = props;

    const handleStartChat = async () => {
        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        // To check if the client has got coupon from the advisor
        try {
            setCheckingAvailCoupon(true);

            let response = await ApiService.get('/client/setting/index');
            const chatMinTime = floatV(response.data[SettingFields.CHAT_MIN_TIME]);

            response = await ApiService.get('/client/coupon/available/' + advisor._id);
            if(!response.data.coupon && authUser.balance < (chatMinTime-1) * advisor.rate_per_min_chat) {
                // if client's balance is less than limit, then goto payment page.
                // Upon payment made with success, it would emit request_chat message.
                JwtService.setPaymentRequest({
                    purpose: 'request_chat',
                    advisor: advisor,
                });
                modalContext.setOpenPayAmountModal(true);
                return;
            } else {
                modalContext.setModalData({advisor: advisor});
                modalContext.setOpenEnoughBalanceModal(true);
            }
        } finally {
            setCheckingAvailCoupon(false);
        }
    }

    const gotoAdvisorDetailPage = () => {
        navigate(v_url('/psychic/' + advisor.username.replaceAll(' ', '-')));
    }

    const renderLargeVersion = () => (
        <div className={`advisor-comm d-flex`}>
            <div className="chat w-100 d-flex flex-column justify-content-center align-items-center">
                { advisor?.serviceChatStatus === 'online' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid online p-1 px-md-3`} 
                            disabled={checkingAvailCoupon}
                            onClick={handleStartChat} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={30} height={30} />
                        <span className="ms-2">Chat Now</span>
                    </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'busy' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid busy p-1 px-md-3`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={30} height={30} />
                        <span className="ms-2">Busy</span>
                    </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'offline' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid offline p-1 px-md-3`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/envelope.svg' className="chat-icon" alt='Chat' width={25} height={30} />
                        <span className="ms-2">Contact</span>
                    </MDBBtn>
                }

                <div className="mt-1 d-flex w-100 px-2 fs-90">
                    <div className="flex-fluid">Live Chat</div>
                    <div className="flex-fluid">${ advisor?.rate_per_min_chat }/min</div>
                </div>
            </div>
        </div>
    )

    const renderMediumVersion = () => (
        <div className={`advisor-comm d-flex medium-version`}>
            <div className="chat w-100 d-flex flex-column justify-content-center align-items-center">
                { advisor?.serviceChatStatus === 'online' && 
                <MDBBtn className={`w-100 chat-btn flex-fluid online p-1 px-md-3`} 
                        disabled={checkingAvailCoupon}
                        onClick={handleStartChat} 
                        style={{textTransform: 'none'}}
                >
                    <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={30} height={30} />
                    <span className="ms-2">Chat Now</span>
                </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'busy' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid busy p-1 px-md-3`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={30} height={30} />
                        <span className="ms-2">Busy</span>
                    </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'offline' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid offline p-1 px-md-3`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/envelope.svg' className="chat-icon" alt='Chat' width={25} height={30} />
                        <span className="ms-2">Contact</span>
                    </MDBBtn>
                }
            </div>
        </div>       
    )

    const renderSmallVersion = () => (
        <div className={`advisor-comm d-flex small-version`}>
            <div className="chat w-100 d-flex flex-column justify-content-center align-items-center">
                { advisor?.serviceChatStatus === 'online' &&
                    <MDBBtn className={`w-100 chat-btn flex-fluid online p-1 px-2`} 
                            disabled={checkingAvailCoupon}
                            onClick={handleStartChat} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={20} height={20} />
                    </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'busy' &&
                    <MDBBtn className={`w-100 chat-btn flex-fluid busy p-1 px-2`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/chat.svg' className="chat-icon" alt='Chat' width={20} height={20} />
                    </MDBBtn>
                }

                { advisor?.serviceChatStatus === 'offline' && 
                    <MDBBtn className={`w-100 chat-btn flex-fluid offline p-1 px-2`} 
                            onClick={gotoAdvisorDetailPage} 
                            style={{textTransform: 'none'}}
                    >
                        <SVG src='/img/icons/envelope.svg' className="chat-icon" alt='Chat' width={20} height={20} />
                    </MDBBtn>
                }
            </div>
        </div>
    )

    if(props.size == 'sm') {
        return renderSmallVersion();
    } else if(props.size == 'md') {
        return renderMediumVersion();
    } else {
        return renderLargeVersion();
    }
});

export default CommPanel;