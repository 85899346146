import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";


function HowChatSection(props) {

    return (
        <section className="how-chat-section">
            <MDBContainer className="section-container" breakpoint="sm">
                <MDBRow>
                    <MDBCol size={12} md={6}>
                        <MDBRow className="section-title-row d-flex justify-content-center ">
                            <div  className="section-title align-self-center d-flex flex-row justify-content-center">
                                {/* <img className="satisfaction-gold-medal" src="/img/backgrounds/sections/gold_medal.svg" loading="lazy"/> */}
                                <h1 className="ms-2"
                                >Why Choose Confideas?</h1>
                            </div>
                            </MDBRow>

                            <MDBRow 
                                className="why-choose-description"
                                style={{
                                    background: "#f6f2e7",
                                    borderRadius: "8px",
                                    paddingTop: "8px",
                                    paddingBottom: "8px"
                                }}>
                                <MDBCol size={12}>
                                    {/* <img className="satisfaction-tick" src="/img/backgrounds/sections/tick.svg" loading="lazy"/> */}
                                    <p className="why-choose-description-subtitle">
                                        Satisfaction Guaranteed
                                    </p>
                                    <p className="why-choose-description-text">
                                        You pay as you go and can disconnect at anytime. Unused credit will carry over for future use and will never expire.
                                    </p>
                                </MDBCol>
                                <MDBCol size={12}>
                                    {/* <img className="satisfaction-tick" src="/img/backgrounds/sections/tick.svg" loading="lazy"/> */}
                                    <p className="why-choose-description-subtitle">
                                        Mobile-Friendly Chatroom
                                    </p>
                                    <p className="why-choose-description-text">
                                        Connect via Safari and Chrome without extra app required.
                                    </p>
                                </MDBCol>
                                <MDBCol size={12}>
                                    {/* <img className="satisfaction-tick" src="/img/backgrounds/sections/tick.svg" loading="lazy"/> */}
                                    <p className="why-choose-description-subtitle">
                                        Onsite Mail System
                                    </p>
                                    <p className="why-choose-description-text">
                                        Keep in touch with your advisors by clicking the purple envelope on their profile page once loggin in.
                                    </p>
                                </MDBCol>
                                <MDBCol size={12}>
                                    {/* <img className="satisfaction-tick" src="/img/backgrounds/sections/tick.svg" loading="lazy"/> */}
                                    <p className="why-choose-description-subtitle">
                                        Verified Advisors
                                    </p>
                                    <p className="why-choose-description-text">
                                        All advisors are authenticated with a government-issued photo ID.
                                    </p>
                                </MDBCol>
                                <MDBCol size={12}>
                                    {/* <img className="satisfaction-tick" src="/img/backgrounds/sections/tick.svg" loading="lazy"/> */}
                                    <p className="why-choose-description-subtitle">
                                        Clients' Privacy and Anonymity
                                    </p>
                                    <p className="why-choose-description-text">
                                        Clients can sign up using one email address, and conversations are confidential.
                                    </p>
                                </MDBCol>
                            
                            </MDBRow>
                    </MDBCol>
                    <MDBCol size={12} md={6} className="start-chart-column">
                        <MDBRow className="section-title-row d-flex justify-content-center ">
                            <div  className="section-title align-self-center">
                                <h1 className="ms-2"
                                style={{
                                    paddingTop: '7px',
                                    textAlign: 'center',
                                }}>How to Start Your Psychic Chat?</h1>
                            </div>
                        </MDBRow>
                        <MDBRow className="d-flex">
                            <img className="how-chat-img"
                                loading="lazy"
                                src="/img/backgrounds/howchat.png"/>
                        </MDBRow>

                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}

export default HowChatSection;