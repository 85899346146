import { MDBContainer, MDBInputGroup, MDBIcon, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import SVG from 'react-inlinesvg';
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { UserRole } from "../../../Constants/constants";
import { IsChatSocketConnected } from "../../../Store/Reducer/socketReducer";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";
import Flicking, {ViewportSlot} from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";
import "@egjs/flicking-plugins/dist/arrow.css";

import * as motion from 'motion/react-client';

import { Arrow, AutoPlay } from "@egjs/flicking-plugins";
import Advisor from "../../../Components/Advisor/Advisor";

const TopAdvisors = React.memo(({}) => {
    // console.log('calling advisor list....')
    const perPageOpts = [
        { value: 12,label: "12" },
        { value: 16, label: "16" },
        { value: 20, label: "20" }
    ];
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loadingAdvisors, setLoadingAdvisors] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [availCoupons, setAvailCoupons] = useState(null);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const navigate = useNavigate();

    const plugins = [new Arrow()];
    // const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: true }), new Arrow()];

    const getTopAdvisors = async () => {
        try {
            setLoadingAdvisors(true);

            let response = await ApiService.get(`/client/advisor/top`);

            setAdvisors(response.data.advisors);

            if(isAuthenticated && authUser.role === UserRole.CLIENT && response.data.advisors.length > 0) {
                // To get available coupons
                const aIds = response.data.advisors.map(ad => ad._id);
                response = await ApiService.post('/client/coupon/available/bulk', {aIds});
                setAvailCoupons(response.data.coupons);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingAdvisors(false);
        }
    }

    useEffect(()=>{
        getTopAdvisors();
        if(!isAuthenticated) {
            setAvailCoupons(null);
        }
    }, [isAuthenticated]);

    const getAvailCoupon = (aId) => {
        if(isAuthenticated && availCoupons?.length > 0) {
            const availCoupon = availCoupons.find(coupon=>coupon.aId === aId);
            return availCoupon ?? null;
        }
        return null;
    }

    const gotoAllAdvisors = () => {
        navigate(v_url('/all_advisors'));
    }

    const renderAdvisors = () => {
        if(advisors?.length == 0) {
            return <div className="text-center">
                {loadingAdvisors ? '' : <><SVG src='/img/icons/no_found.svg' style={{height:80}} /><div className="mt-1">No Found Advisors</div></>}
            </div>
        } else {
            return <MDBRow>
                { advisors?.map((a, index) => {
                    return <MDBCol className="advisor-container p-1" size={6} xl='3' lg="4" md='4' sm='6' key={index}>
                        <Advisor advisor={a} availCoupon={getAvailCoupon(a._id)} />
                    </MDBCol>
                })}
            </MDBRow>
        }
    }

    return (
        <div className="advisor-list-container" id='advisors'>
            <MDBContainer breakpoint="lg" style={{
                position: 'relative',
                padding: 0,
                background: '#faf8f1',
            }}>
                <MDBRow className="advisor-list-header-row">
                    <MDBCol className="text-center advisor-list-header-colume" size={12}>
                        <img src="/img/backgrounds/flower1.png" className="section-title-bg-item flower" alt="" loading="lazy" />
                        <MDBBtn className="d-flex align-items-center justify-content-center" rounded={true}
                            onClick={gotoAllAdvisors}
                            style={{
                                background: '#f1dd00',
                                marginLeft: 'auto',
                                marginRight: '20px',
                            }}>
                            <span className="text-dark fs-120">Explore Advisors</span>
                        </MDBBtn>
                        <h1 className="text-center advisor-list-header-title">Our Featured Advisors</h1>
                    </MDBCol>
                </MDBRow>
                <div className="p-2 pt-0">
                    { renderAdvisors() }
                    {loadingAdvisors && <LoadingIndicator fullScreen={true} />}
                </div>
                <div>
                    <img src="/img/backgrounds/bg_yellow.png" className="advisor-list-container-bg" width="100%" height="600px" alt="" loading="lazy" />
                </div>
                {loadingAdvisors && <LoadingIndicator fullScreen={true} />}
            </MDBContainer>
        </div>
    )
})

export default TopAdvisors;