import React from "react";
import styles from './Advisor.scss';
import SVG from 'react-inlinesvg';
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../Store/Reducer/changedAdvisorReducer";
import { getAvatarUrl, v_url } from "../../Utils/utils";
import ApiService from "../../Core/Service/ApiService";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { useNavigate } from "react-router-dom";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { SettingFields, TEST_MODE } from "../../Constants/constants";
import JwtService from "../../Core/Service/JwtService";

const Advisor2 = React.memo(props=>{
    const navigate = useNavigate();
    const {advisor} = props;
    const [advisorInfo, setAdvisorInfo] = useState(advisor);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);
    const modalContext = useContext(ModalContext);
    const authUser = useSelector(memoizedGetAuthUser);
    const [minChatTime, setMinChatTime] = useState(5);

    useEffect(() => {
        ApiService.get('/client/setting/index').then(result => {
            setMinChatTime(result.data[SettingFields.MIN_CHAT_TIME]);
        });
    }, []);
    useEffect(()=>{
        if(advisor) updateAdvisorInfo(advisor);
    }, [advisor]);
    useEffect(()=>{
        if(changedAdvisorHash) {
            const chgAdvisor = changedAdvisors.find(a=>a._id === advisor._id);
            if(chgAdvisor) updateAdvisorInfo(chgAdvisor);
        }
    }, [changedAdvisorHash])

    const updateAdvisorInfo = (adInfo) => {
        const info = {...adInfo};
        if(info.isLoggedIn == 0) {
            info.serviceChatStatus = 'offline';
        } else {
            if(info.isBusy) {
                info.serviceChatStatus = 'busy';
            } else {
                info.serviceChatStatus = info.isAvailableChat ? 'online' : 'offline';
            }
        }
        setAdvisorInfo(info);
    }
    const onChatBtnClicked = async () => {
        if(authUser) {
            ApiService.get('/client/coupon/available/' + advisorInfo._id).then(response=>{
                const availCoupon = response.data.coupon;
                if(!availCoupon && authUser.balance < minChatTime * advisorInfo.rate_per_min_chat) {
                    JwtService.setPaymentRequest({
                        purpose: 'request_chat',
                        advisor: advisorInfo,
                    });
                    modalContext.setOpenPayAmountModal(true);
                } else {
                    modalContext.setModalData({advisor: advisorInfo});
                    modalContext.setOpenEnoughBalanceModal(true);
                }
            });
        }
    }
    const gotoAdvisorDetail = () => {
        if(advisorInfo) navigate(v_url('/advisor/' + advisorInfo._id))
    }

    return <div className={`d-flex align-items-center advisor2-preview ${advisorInfo?.serviceChatStatus}`}>
        <img 
            loading="lazy"
            src={getAvatarUrl(advisorInfo?.avatar)}
            alt=''
            className='circle-avatar-40 cursor-pointer'
            onClick={gotoAdvisorDetail}
        />
        <div className="mx-2">
            <div className="ps-1 cursor-pointer" onClick={gotoAdvisorDetail}>
                {advisorInfo?.username}
            </div>
            {advisorInfo?.serviceChatStatus == 'online' && 
                <div onClick={onChatBtnClicked} className="cursor-pointer" title='Chat'>
                    <SVG 
                        src='/img/icons/message.svg' 
                        width={30} 
                        height={24} 
                        fill="green"
                    />
                </div>
            }
        </div>
    </div>
});

export default Advisor2;