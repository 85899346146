import CryptoJS from "crypto-js";
import { BREAKPOINT_WIDTH } from "../Constants/constants";
import moment from "moment-timezone";
import { isThisYear } from "date-fns";

export const getAvatarUrl = (uri) => {
    if(uri) return getBackendAssetUrl(uri);
    return '/img/others/default_user.png';
}

export const getBackendAssetUrl = (uri) => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    return `${baseUrl}/${uri}`;
}

export const format_time_mm_ss = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return m.toString().padStart(2, '0') + ' : ' + s.toString().padStart(2, '0');
}

export const formatSeconds = (seconds, long=false) => {
    const oneDaySeconds = 24*3600;
    if(seconds < 0) return '';

    if(long == false) {
        const d = Math.floor(seconds / oneDaySeconds);
        if(d > 0) {
            return `${d} day`;
        } else {
            const h = Math.floor(seconds / 3600);
            if(h > 0) {
                return `${h} hour`
            } else {
                const m = Math.floor(seconds / 60);
                if(m > 0) {
                    return `${m} min`
                }
                return `${seconds} sec`;
            }
        }
    } else {
        const d = Math.floor(seconds / oneDaySeconds);
        seconds = seconds % oneDaySeconds;
        
        const h = Math.floor(seconds / 3600);
        seconds = seconds % 3600;

        const m = Math.floor(seconds / 60);
        seconds = seconds % 60;

        let result = '';
        if(d > 0) result = `${d} day  `;
        if(h > 0) result += `${h} hour  `;
        if(m > 0) result += `${m} min  `;
        if(seconds > 0 || !result) result += `${seconds} sec`;

        return result;
    }
}

export const getTimeDiff = (time1, time2 = null) => {
    if(!time2) time2 = new Date();
    
    const timeStampDiff = parseInt((time1.getTime() - time2.getTime()) / 1000);      // timestamp in seconds
    if(timeStampDiff <= 0) return '';

    return formatSeconds(timeStampDiff);
}

export const formatDate_YYYY_MM_DD = (date) => {
    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();
    return `${Y}-${M}-${D}`;
}

export const formatDate = (date, format) => {
    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();
    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();

    switch(format) {
        case 'YYYY/MM/DD HH:mm':
            return `${Y}/${M}/${D} ${h}:${m}`;
        case 'YYYY-MM-DD HH:mm':
            return `${Y}-${M}-${D} ${h}:${m}`;
        case 'YYYY/MM/DD':
            return `${Y}/${M}/${D}`;
        case 'YYYY-MM-DD':
            return `${Y}-${M}-${D}`;
        case 'HH:mm':
            return `${h}:${m}`;
    }
}

export const validateForm = (formData, validRules) => {
    const errors = {};
    let result = true;

    Object.keys(validRules).forEach(element => {
        const rules = validRules[element];
        const label = rules['label'] ?? capitalizeFirstLetter(element);
        const messages = rules['messages'];
        const v = formData[element];

        const r_keys = Object.keys(rules);
        if(r_keys.length === 0) return;

        for(let i=0; i<r_keys.length; i++) {
            const r_k = r_keys[i];
            const r_v = rules[r_k];

            if(r_k === 'required') {
                if(!v) {
                    errors[element] = (messages && messages[r_k]) ?? `${label} is required.`;
                    result = false;
                    break;
                }
                if(r_v === 'string' && typeof(v) != 'string') {
                    errors[element] = (messages && messages[r_k]) ?? `${label} should be of string format.`;
                    result = false;
                    break;
                }
                if(r_v === 'number' && !parseInt(v)) {
                    errors[element] = (messages && messages[r_k]) ?? `${label} should be of number format.`;
                    result = false;
                    break;
                }
                if(r_v === 'array' && v.length === 0) {
                    errors[element] = (messages && messages[r_k]) ?? `${label} is required.`;
                    result = false;
                    break;
                }
                if(r_v === 'object' && (typeof(v) != 'object' || Object.keys(v).length === 0) ) {
                    errors[element] = (messages && messages[r_k]) ?? `${label} is required.`;
                    result = false;
                    break;
                }
                if(r_v === 'email' && !/\S+@\S+\.\S+/.test(v)) {
                    errors[element] = (messages && messages[r_k]) ?? `Please input the correct email address.`;
                    result = false;
                    break;
                }
                if(r_v === 'video') {
                    const allowedTypes = [
                          'video/mp4',
                            'video/avi',
                            'video/mov',
                            'video/wmv',
                            'video/webm',
                            'video/3gp',
                            'video/3g2',
                            'video/flv',
                            'video/mkv',
                            'video/ogg',
                            'video/x-matroska',
                            'video/quicktime',
                            'video/x-msvideo',
                            'video/x-ms-wmv',
                            'video/x-flv',
                            'video/vnd.rn-realvideo',
                            'video/vnd.divx',
                            'video/vnd.dlna.mpeg-tts'
                    ];
                    if (!v) {
                        errors[element] = (messages && messages[r_k]) ?? `Video is required.`;
                        result = false;
                        break;
                    }
                    if (!v || (v && !allowedTypes.includes(v.type))) {
                        errors[element] = (messages && messages[r_k]) ?? `Please input the correct video file.`;
                        result = false;
                        break;
                    }
                }
            }

            if(r_k === 'minlength' && v && v.length < r_v) {
                errors[element] = (messages && messages[r_k]) ?? `Please input at least ${r_v} characters.`;
                result = false;
                break;
            }

            if(r_k === 'maxlength' && v && v.length > r_v) {
                errors[element] = (messages && messages[r_k]) ?? `Please input at most ${r_v} characters.`;
                result = false;
                break;
            }

            if(r_k === 'min' && v && parseFloat(v) < r_v) {
                errors[element] = (messages && messages[r_k]) ?? `Please input larger than ${r_v}.`;
                result = false;
                break;
            }

            if(r_k === 'max' && v && parseFloat(v) > r_v) {
                errors[element] = (messages && messages[r_k]) ?? `Please input less than ${r_v}.`;
                result = false;
                break;
            }

            if(r_k === 'equal') {
                const compare_v = formData[r_v];
                if(v != compare_v) {
                    errors[element] = (messages && messages[r_k]) ?? `${label} should be the same value as ${capitalizeFirstLetter(r_v)}`;
                    result = false;
                    break;
                }
            }

            if(r_k === 'match') {
                if(!r_v.test(v)) {
                    errors[element] = (messages && messages[r_k]) ?? `Please input the correct format`;
                    result = false;
                    break;
                }
            }
        }
    })

    return {
        result,
        errors
    }
}

export const showWebNotification = (title, message, callback) => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('sent notification.')
            const notification = new Notification(title, {
                body: message,
                icon: '/img/logo/logo.svg',
            });

            // Add click event listener to the notification
            notification.onclick = function(event) {
                event.preventDefault(); // Prevent the browser from focusing the Notification's tab
                window.focus(); // Focus the current tab/window
                this.close(); // Close the notification
            };
            
            callback && callback(true);
        } else {
            callback && callback(false);
        }
    })
    .catch(error=>{
    })
}

export const updateFavIcon = (iconUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
}

export const getSHA256 = (plain) => {
    const jsonString = JSON.stringify(plain);
    const hash = CryptoJS.SHA256(jsonString);
    return hash.toString(CryptoJS.enc.Hex);
}

export const getFirstDateOfNextMonth = (year, month) => {
    const firstDateOfNextMonth = new Date(year, month + 1, 1);
    return firstDateOfNextMonth;
}

export const floatV = (v) => {
    if(!v) return 0;
    if(typeof(v) === 'string') v = parseFloat(v);
    const fv = parseFloat(v.toFixed(2));
    return fv;
}

export const scrollUpTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}

export const scrollDownBottom = () => {
    window.scroll({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
    });
}

export const containsSpecialLetters = (str) => {
    var specialLetters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialLetters.test(str);
}

export const addDate = (date, days) => {
    return new Date(date.getTime() + days * 24 * 3600 * 1000);
}

export const compareDates = (date1, date2) => {
    const t1 = date1.getTime();
    const t2 = date2.getTime();
    if(t1 === t2) return 0;
    return (t1 > t2) ? 1 : -1;
}

export const text2html = (text) => {
    if(!text) return '';
    text = text.replaceAll('\n', '<br/>')
    return text;
}
export const html2Text = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
}

export const capitalizeFirstLetter = (text) => {
    let start = 0, result = "";
    while(text.length && start < text.length) {
        if(text[start] == ' ') {
            result += text[start];
            start++;
            continue;
        }
        return result + text.charAt(start).toUpperCase() + text.slice(start + 1);
    }
    return text;
}

export const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
};

export const isValidEmail = (emailAddress) => {
    return /\S+@\S+\.\S+/.test(emailAddress);
}

export const scrollToElement = (element) => {
    if(element) {
        const topPos = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: topPos - 100,
            behavior: 'smooth'
        });
    }
}

export const isMobileScreen = () => {
    return window.visualViewport.width < BREAKPOINT_WIDTH;
}

export const v_url = (url) => {
    if(url[0] == '/') return `/v${process.env.REACT_APP_VERSION}${url}`;
    else return `/v${process.env.REACT_APP_VERSION}/${url}`;
}

export const formatSSN = (newValue, oldValue, s='-') => {
    // handle backspace at dash positions
    if (
      oldValue.substr(-1) === s &&
      newValue === oldValue.substring(0, oldValue.length - 1)
    ) {
      return oldValue.substring(0, oldValue.length - 2);
    }

    // strip out non-numeric characters
    newValue = newValue.replace(/[^0-9]/g, "");
    // insert dashes & limit to 9 characters
    if (newValue.length > 2 && newValue.length < 5) {
      newValue = newValue.substring(0, 3) + s + newValue.substring(3);
    } else if (newValue.length >= 5) {
      newValue =
        newValue.substring(0, 3) +
        s +
        newValue.substring(3, 5) +
        s +
        newValue.substring(5, 9);
    }

    return newValue;
  };

export const isValidSSN = (ssn) => {
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
    return ssnPattern.test(ssn);
}

export const estDate = (date) => moment.tz(date, 'America/New_York');

export const getPaymentDates = () => {
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    let payMonth = today.getMonth();
    
    if (today.getDate() < 10) payMonth = payMonth - 1;

    const startThisMonthDate = new Date(thisYear, payMonth, 1);
    const endThisMonthDate = new Date(thisYear, payMonth + 1, 0);
    const nextPayoutDate = new Date(thisYear, payMonth + 1, 10);
    const options = {
        month: 'long',
        day: 'numeric'
    };
    return {
        sDate: startThisMonthDate.toLocaleString('en-US', options),
        eDate: endThisMonthDate.toLocaleString('en-US', options),
        pDate: nextPayoutDate.toLocaleString('en-US', options),
    }
}
