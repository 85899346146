import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, {useEffect} from "react";
import SVG from 'react-inlinesvg';
import MainInfo from "./MainInfo";
import AboutMe from "./AboutMe";
import Reviews from "./Reviews";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useSelector } from "react-redux";
import { getLastestAdvisorInfo } from "../../../Store/Reducer/changedAdvisorReducer";
import { getAvatarUrl, getBackendAssetUrl, v_url } from "../../../Utils/utils";
import { IsAuthenticated, memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import FloatBar from "../../../Components/FloatBar/FloatBar";
import CommPanel from "../../../Components/CommPanel/CommPanel";
import {Helmet} from 'react-helmet';

const AdvisorView = React.memo((props) => {
    const navigate = useNavigate();
    const advisorUpdtInfo = useSelector((state) => getLastestAdvisorInfo(state, props.advisor));
    const isAuthenticated = useSelector(IsAuthenticated);
    
    const goBack = () => {
        navigate(v_url(isAuthenticated ? '/all_advisors' : '/'));
    }

    const renderVideo = () => {
        return (
        <MDBCard className="w-100 h-100 mt-4">
            <MDBCardBody className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-left w-100 d-flex align-items-center mb-2">
                    <SVG src='/img/icons/movie_videos.svg' style={{width:25, height: 25}} fill="#54b4d3" />
                    <h5 className="m-0 ms-2">Introduction Video:</h5>
                </div>
                <video 
                    controls 
                    className="w-90" 
                    src={getBackendAssetUrl(advisorUpdtInfo.video)} 
                    style={{maxWidth:300, maxHeight:300}} 
                />
            </MDBCardBody>
        </MDBCard>
        )
    }

    const renderFloatBar = () => {
        return (
        <FloatBar triggerY={145} mobile_float_y={60} pc_float_y={80}>
            <MDBBtn 
                color='none' tag='a'
                className='p-1'
                onClick={goBack}
            >
                <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
            </MDBBtn>
            
            <img 
                className="ms-1 circle-avatar-40" 
                src={getAvatarUrl(advisorUpdtInfo?.avatar)} 
                style={{
                    border: '3px solid var(--primary-yellow)'
                }}
            />
            <span className="ms-2">{ advisorUpdtInfo?.username }</span>

            <div className="ms-4">
                <div className="d-none d-md-block">
                    <CommPanel advisor={advisorUpdtInfo} size='md' />
                </div>
                <div className="d-block d-md-none">
                    <CommPanel advisor={advisorUpdtInfo} size="sm" />
                </div>
            </div>
        </FloatBar>
        )
    }

    return (<>
        <Helmet>
            <link rel="canonical" href={`https://confideas.com/v0.28/psychic/${advisorUpdtInfo.username}`} />
            <meta name="keywords" content={`${advisorUpdtInfo.username}`}
            />
        </Helmet>

        <MDBContainer breakpoint="lg" className="advisor-show-container p-2 p-md-4 position-relative">
            {renderFloatBar()}

            <div className="go-back-bar">
                <MDBBtn 
                    color='none' tag='a'
                    className='p-1'
                    onClick={goBack}
                >
                    <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
                </MDBBtn>
            </div>

            <MainInfo advisor={advisorUpdtInfo} />
            <AboutMe advisor={advisorUpdtInfo} />
            {advisorUpdtInfo?.video && renderVideo()}
            <Reviews advisor={advisorUpdtInfo} />
        </MDBContainer>
    </>)
});

const AdvisorShow = React.memo((props) => {
    const params = useParams();
    const aId = params._id;
    const uName = params.uName;
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
  
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        setLoading(true);

        let url = "";
        if(aId) url = '/client/advisor/' + aId;
        else if(uName) url = '/client/advisor/uname/' + uName.replaceAll('-', ' ');
        ApiService.get(url).then(result=>{
            if(result.data.advisor) {
                setAdvisor(result.data.advisor);
            } else {
                navigate(v_url('/all_advisors'));
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    }, []);

    return (<>
        {advisor && <AdvisorView advisor={advisor} />}
    </>)
}, (oldProps, newProps)=>{
    return oldProps.aId == newProps.aId;
})

export default AdvisorShow;