import React, {useContext, useEffect, useState} from 'react';
import { 
    MDBContainer, 
    MDBIcon, 
    MDBFooter,
    MDBRow,
    MDBCol,
    MDBBtn
 } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IsAuthenticated, memoizedGetAuthUser } from '../../Store/Reducer/authReducer';
import ModalContext from '../../Context/ModalContext';
import { UserRole } from '../../Constants/constants';
import { v_url } from '../../Utils/utils';
import SVG from 'react-inlinesvg';

 const Footer = React.memo(() => {
    const isLogged = useSelector(IsAuthenticated);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const [expanded, setExpanded] = useState(false);

    const handleContactUs = (e) => {
        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser.role === UserRole.ADMIN) return;

        modalContext.setOpenSendOpinionModal(true);
    }

    const renderBecomeAdvisor = () => {
        return <div className='become-advisor'>
            <div className='d-flex align-items-center'>
                <Link 
                    to={v_url('/advisor_auth/signup')}
                    className='d-flex header align-items-center fw-bold'
                    style={{color: '#cb17c5'}}
                >
                    <MDBIcon fas icon="user-graduate" className='me-2' />
                    <span className='underline-bar'>Become an Advisor</span>
                </Link>
                <MDBIcon className='ms-2 hand-point-left' far icon="hand-point-left" size='lg' color='black-50' />
            </div>
            <p className='text-left'>
                Are you a gifted spiritual advisor with exceptional insights? 
                Join Confideas and leverage your unique talents to connect with clients
                worldwide in a supportive environment.
            </p>
        </div>
    }

    const expandFooter = (expand) => {
        setExpanded(expand);
        if(expand) {
            setTimeout(()=>{
                let scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
                window.scroll({
                    top: scrollableHeight,
                    behavior: 'smooth'
                })
            }, 200);
        }
    }

    const renderDashboardPCVersion = () => {
        return (
        <MDBFooter className='d-md-block text-center text-lg-start footer' style={{
            position: 'relative',
            background: '#f6f2e7',
        }}>
            <MDBContainer breakpoint='md' >
                <div className='d-none d-md-block'>
                    {expanded && (
                    <>
                    {/* <MDBRow center className="py-4">
                            <MDBCol size={3} />
                            <MDBCol size={3} />
                            <MDBCol size={3} className='footer-link'><Link to={v_url('/about_us')}><>About Confideas</></Link></MDBCol>
                            <MDBCol size={3} />
                    </MDBRow> */}
                    <MDBRow center className="">
                            <MDBCol size={3} />
                            <MDBCol size={3} />
                            <MDBCol size={3} className='footer-link'><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></MDBCol>
                            <MDBCol size={3} />
                    </MDBRow>   
                    <MDBRow center className="">
                            <MDBCol size={3} />
                            <MDBCol size={3} />
                            <MDBCol size={3} className='footer-link'><Link to={v_url('/privacy_policy')}><nobr>Privacy Policy</nobr></Link></MDBCol>
                            <MDBCol size={3} />
                    </MDBRow>
                            { isLogged && authUser?.role === UserRole.ADVISOR && 
                            <MDBRow center className="">
                                <MDBCol size={3} />
                                <MDBCol size={3} />
                                <MDBCol size={3} className='footer-link'><Link to={v_url('/terms_of_use')}><nobr>Advisor Terms & Conditions</nobr></Link></MDBCol>
                                <MDBCol size={3} />
                            </MDBRow>  
                            }
                    {/* <MDBRow center className="py-4">
                            <MDBCol size={3} />
                            <MDBCol size={3} />
                            <MDBCol size={3} className='footer-link'><Link onClick={handleContactUs}><nobr>Contact Us</nobr></Link></MDBCol>
                            <MDBCol size={3} />
                    </MDBRow> */}
                    </>
                    )}
                    <MDBRow center className="">
                        <MDBCol  size={12} center={true}>
                            <MDBBtn 
                            color='tertiary'
                            className="w-20 d-flex align-items-center justify-content-center" size="lg" rounded={true}
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                border: 'none',
                            }}>
                            <div style={{
                                background: '#0165a2',
                                borderRadius: '50%',
                                padding: '10px',
                                alignContent: 'center'
                            }}>
                                <MDBIcon far icon='envelope' size="lg" color="light" />
                            </div>
                            <span className="ms-2 contact-link">contact@confideas.com</span>

                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    <div className='footer-expand'
                        style={{
                            bottom: '10px',
                            right: '100px',
                        }}>
                        <MDBBtn tag='a' color='none' onClick={e=>expandFooter(!expanded)}>
                            <MDBIcon fas icon={expanded ? "chevron-down" : "chevron-up"} size='2x' />
                        </MDBBtn>
                    </div>
                </div>
            </MDBContainer>
        </MDBFooter>
        )
    }

    const renderClientPCVersion = () => {
        return (
            <MDBFooter className='d-md-block text-center text-lg-start footer'>
                <MDBContainer breakpoint="lg" className='p-3'>
                    <div className='d-none d-md-block client-footer' style={{position: 'relative'}}>
                        {expanded && (
                            <>
                                {!isLogged && renderBecomeAdvisor() }
                                <MDBRow className='text-left fw-bold mb-2 footer-item'>
                                    <MDBCol><Link to={v_url('/about_us')}><nobr>About Confideas</nobr></Link></MDBCol>
                                    <MDBCol><Link to={v_url('/privacy_policy')}><nobr>Privacy Policy</nobr></Link></MDBCol>
                                    <MDBCol><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></MDBCol>
                                    { isLogged && authUser?.role === UserRole.ADVISOR && 
                                        <MDBCol><Link to={v_url('/terms_of_use')}><nobr>Advisor Terms & Conditions</nobr></Link></MDBCol>
                                    }
                                    <MDBCol><Link onClick={handleContactUs}><nobr>Contact Us</nobr></Link></MDBCol>
                                </MDBRow>
                            </>
                        )}
                        <div className='d-flex justify-content-center align-items-center' style={{marginRight: 50}}>
                            <div>©2024 Confideas. All Rights Reserved.</div>
                            <div className='ms-4 flex-fluid d-flex justify-content-end align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                                    <span className='contact-email'>contact@confideas.com</span>
                                </div>
                            </div>
                        </div>

                        <div className='footer-expand'
                        style={{
                            right: '5px',
                            bottom: '0px',
                        }}>
                            <MDBBtn tag='a' color='none' onClick={e=>expandFooter(!expanded)}>
                                <MDBIcon fas icon={expanded ? "chevron-down" : "chevron-up"} size='' />
                            </MDBBtn>
                        </div>
                    </div>
                </MDBContainer>
            </MDBFooter>
        )
    }

    const renderMobileVersion = () => {
        return (
            <div className='d-md-none text-left p-2' style={{position: 'relative'}}>
                {expanded && (
                    <>
                        {!isLogged && renderBecomeAdvisor() }
                        <div><Link to={v_url('/about_us')}>About Confideas</Link></div>
                        <div><Link to={v_url('/privacy_policy')}>Privacy Policy</Link></div>
                        <div><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></div>
                        <div><Link onClick={handleContactUs}><nobr>Contact Us</nobr></Link></div>
                        <div className='d-flex align-items-center mt-2'>
                            <div>©2024 Confideas</div>
                            <div className='flex-fluid d-flex justify-content-end align-items-center me-4'>
                                <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                                <span>contact@confideas.com</span>
                            </div>
                        </div>

                        <div className='footer-expand'>
                            <MDBBtn tag='a' color='none' onClick={e=>expandFooter(false)}>
                                <MDBIcon fas icon="chevron-down" size='' />
                            </MDBBtn>
                        </div>
                    </>
                )}
                {!expanded && (
                    <>
                        <div className='fw-400 text-center'>
                            <div className='d-flex align-items-center'>
                                <div>©2024 Confideas</div>
                                <div className='flex-fluid d-flex justify-content-end align-items-center me-4'>
                                    <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                                    <span>contact@confideas.com</span>
                                </div>
                            </div>
                            <div className='footer-expand'>
                                <MDBBtn tag='a' color='none' onClick={e=>expandFooter(true)}>
                                    <MDBIcon fas icon="chevron-up" size='' />
                                </MDBBtn>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <>
            { isLogged && authUser?.role === UserRole.CLIENT && renderClientPCVersion() }
            { (!isLogged || authUser?.role !== UserRole.CLIENT) && renderDashboardPCVersion()}
                {/* {renderMobileVersion()} */}
        </>
    )
 });

 export default Footer;