import React from "react";
import { text2html } from "../../../Utils/utils";
import { MDBCard, MDBCardBody, MDBIcon } from "mdb-react-ui-kit";
import { CategoryTags, UserRole } from "../../../Constants/constants";

function AboutMe(props) {
    const {advisor} = props;

    return (
        <MDBCard className="">
            <MDBCardBody>
                {/* <div className="mt-1 text-gray555">
                    {advisor?.total_chats ?? 0} Readings
                </div> */}

                <section className="mt-2 categories p-2">
                    <div className="category-tags">
                        {advisor?.category_tags?.split(',').map((v, index) => {
                            const tag = CategoryTags.find(t => t.value === v);
                            if(tag) {
                                return <div className="category-tag d-flex align-items-center mb-2 me-1" key={index}>
                                    <MDBIcon fas icon="hand-holding-heart" color="purple" />
                                    <span className="ms-2">{tag.label}</span>
                                </div>
                            }
                        })}
                    </div>
                </section>

                <section className="about-me mt-4 p-2">
                    <div className="d-flex align-items-center mb-2">
                        <MDBIcon fas icon="user-graduate" size="lg" />
                        <h5 className="m-0 ms-2">About Me:</h5>
                    </div>
                    <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.aboutme)}} />
                </section>

                <section className="offer-services mt-4 p-2">
                    <div className="d-flex align-items-center mb-2">
                        <MDBIcon fas icon="comment" size="lg" />
                        <h5 className="m-0 ms-2">Services Offered:</h5>
                    </div>
                    { advisor.service_description && 
                        <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.service_description)}}></div>
                    }
                </section>
            </MDBCardBody>
        </MDBCard>
    )
}

export default AboutMe;