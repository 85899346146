import React, {useEffect} from "react";
import Introduction from "./Introdution";
import TopAdvisors from "./TopAdvisors";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Helmet} from 'react-helmet';
import ConsultingTopics from "./ConsultingTopics";
import HowChatSection from "./HowChatSection";
import FirstOfferSection from "./FirstOfferSection";
import { IsAuthenticated } from "../../../Store/Reducer/authReducer";
import BecomeAdvisorSection from "./BecomeAdvisorSection";

function LandingPage(props) {
    // console.log('calling Hompage...')
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(IsAuthenticated);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, []);

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://confideas.com/v0.28" />
                <meta name="keyword" content="
                    Psychic chat
                    Chat with a psychic 
                    Found the one 
                    Live psychic reading 
                    Psychic Consulting
                    Real psychic 
                    Twin flame psychic 
                    Pay per minute text software
                    Confideas 
                    Confide ideas 
                    Online Psychic Network 
                    Psychic advice 
                    Energy healing advice
                    Tarot reading advice 
                    Love experts 
                    Love advice 
                    Relationship advice                
                " />
                <meta name="description" content="Consult one of the best advisors today! Gain clarity on your situation." />
            </Helmet>
            <section>
                <Introduction />
                <TopAdvisors />
            </section>
            <ConsultingTopics />
            {/* <About /> */}
            <FirstOfferSection />
            <HowChatSection />
            {!isAuthenticated && <BecomeAdvisorSection />}
        </>
        
    )
}

export default LandingPage;