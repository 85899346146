import React, {useEffect, useRef} from "react";
import styles from './home.scss';
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import * as motion from 'motion/react-client';
import {useInView} from 'motion/react';
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";

function Topic({topic, index}) {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: false});
  return (
      <div ref={ref} 
        className="confidea-topic-card col-md-6 col-sm-6 col-6"
      >
          <img
            className="confidea-topic-card-bg"

            src={`/img/backgrounds/flower_bg.svg`} alt="t1" loading="lazy"/>  
          <img
            className="topic-img"
            style={{
              // transitionDelay: '0.6 * index'
            }}
            src={`/img/icons/topics/${topic?.image}.svg`} alt="t1" loading="lazy"/>  
        <div
          className="badge d-flex text-wrap topic-title"
          style={{
            position: 'absolute',
            top: '57%',
            left: '0',
            width: '100%',
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            color: '#3f57a2',
            textAlign: 'center',
          }}>
            <MDBCol size={2} md={2} />
            <MDBCol size={8} md={8}>
              <p>
                {topic.title ? topic.title : "Career Insights" }
              </p>
            </MDBCol>
            <MDBCol size={2} md={2}/>
        </div>
      </div>
  )
}

const topics = [
  {
    image: 'star',
    title: 'Career Insights'
  },
  {
    image: 'earth',
    title: 'Psychic Insights'
  },
  {
    image: 'coin',
    title: 'Love Insights'
  },
  {
    image: 'crown',
    title: 'Business Insights'
  }
]

function ConsultingTopics(props) {
  const navigate = useNavigate();
  const _handleExploreAdvisors = (e) => {
    e.preventDefault();
    
    navigate(v_url('/all_advisors'));
  }
    return (
      <section>
        <div className="introduction-container">
            <MDBContainer breakpoint="lg" >
                <MDBRow className="about-title-row d-flex justify-content-center ">
                  <MDBCol lg='12' sm='12' className="about-title align-self-center">
                      {/* <ConsultArea title={ca.title} imageUrl={ca.imageUrl} description={ca.description} url={ca.url} /> */}
                      <MDBRow className="justify-content-center">
                          <MDBCol className="section-title d-flex justify-content-center col-12 align-self-center" style={{position: 'relative'}}>
                            <img src="/img/backgrounds/flower3.png" 
                              className="bg-item-flower"
                              loading="lazy"/>
                              <h1 className="">Real Person Consulting</h1>
                          </MDBCol>
                      </MDBRow>
                      <MDBRow>
                          <MDBCol className="section-title d-flex justify-content-center col-12">
                                  <p>
                                      Confideas: Your Friend, Your Clarity!
                                  </p>
                          </MDBCol>
                      </MDBRow>
                  </MDBCol>
              </MDBRow>
              <div className="confide-topics-head">
                    <p className="">
                      Confideas was founded by intuitive consultants who saw the need for a trusted platform that unites spiritual insight and practical expertise. Grounded in legitimacy and integrity, our advisors offer holistic guidance to help you gain clarity and confidence in both personal and professional situations.                    
                    </p>
                </div>
              <MDBRow className="about-description-container">
                  {/* </TransformImage> */}
                <div className="confide-topics-items">
                    {topics.map((topic, index) => (
                      <Topic topic={topic} key={index} index={index}/>
                    ))}
                </div>
              </MDBRow>

            </MDBContainer>
        </div>
      </section>
    )
}

export default ConsultingTopics;